import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSpinner, Proposals } from '../../components';

import css from './ListingPage.module.css';

const SectionProposals = props => {
  const {
    proposals,
    fetchProposalsInProgress,
    fetchProposalsError,
    onOpenProposal,
    openProposalId,
  } = props;

  const proposalsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.proposalsError" />
    </h2>
  );

  return (
    <div className={css.sectionProposals}>
      <h2 className={css.proposalsTitle}>
        <FormattedMessage id="ListingPage.proposalsTitle" values={{ count: proposals.length }} />
      </h2>
      {fetchProposalsError ? proposalsError : null}

      {fetchProposalsInProgress ? (
        <IconSpinner />
      ) : (
        <Proposals
          proposals={proposals}
          onOpenProposal={onOpenProposal}
          openProposalId={openProposalId}
        />
      )}
    </div>
  );
};

SectionProposals.propTypes = {
  fetchProposalsError: propTypes.error,
  fetchProposalsInProgress: bool.isRequired,
  onOpenProposal: func,
  openProposalId: string,
  proposals: arrayOf(propTypes.transaction),
};

export default SectionProposals;
