import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionQualification = props => {
  const { publicData } = props;

  if (!publicData) {
    return null;
  }

  const neededExperience = publicData.neededExperience;
  const neededSkills = publicData.neededSkills;
  const education = publicData.education;
  const languageSkills = publicData.languageSkills;
  const otherAreasOfConsideration = publicData.otherAreasOfConsideration;

  return (
    <div className={css.sectionContent}>
      <h2 className={css.qualificationTitle}>
        <FormattedMessage id="ListingPage.qualification" />
      </h2>
      <h3 className={css.neededExperienceTitle}>
        <FormattedMessage id="ListingPage.neededExperience" />
      </h3>
      <p className={css.neededExperience}>
        {richText(neededExperience, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      <h3 className={css.neededSkillsTitle}>
        <FormattedMessage id="ListingPage.neededSkills" />
      </h3>
      <p className={css.neededSkills}>
        {richText(neededSkills, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      <h3 className={css.educationTitle}>
        <FormattedMessage id="ListingPage.education" />
      </h3>
      <p className={css.education}>
        {richText(education, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      <h3 className={css.languageSkillsTitle}>
        <FormattedMessage id="ListingPage.languageSkills" />
      </h3>
      <p className={css.languageSkills}>
        {richText(languageSkills, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      <h3 className={css.otherAreasOfConsiderationTitle}>
        <FormattedMessage id="ListingPage.otherAreasOfConsideration" />
      </h3>
      <p className={css.otherAreasOfConsideration}>
        {richText(otherAreasOfConsideration, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  );
};

export default SectionQualification;
