import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import classNames from 'classnames';

import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  Modal,
  JobProgressBar,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import {
  sendProposal,
  spendToken,
  fetchTransactionLineItems,
  setInitialValues,
  updateListing,
} from './ListingPage.duck';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionContent from './SectionContent';
import SectionQualification from './SectionQualification';
import SectionCompensation from './SectionCompensation';
import SectionProposal from './SectionProposal';
import SectionProposals from './SectionProposals';

import css from './ListingPage.module.css';
import ProposalForm from './ProposalForm/ProposalForm';
import NotificationModal from './NotificationModal/NotificationModal';
import JobActions from './JobActions';
import { serialize } from '../../util/api';
import {
  declineFromShortlist,
  declineProposal,
  shortlistRecruiter,
} from '../TransactionPage/TransactionPage.duck';
import {
  txCandidateHasBeenShortlisted,
  txIsCandidateHired,
  txIsCandidatesShortlistAccepted,
  txIsDeclined,
  txIsInFirstReviewBy,
  txIsProposed,
  txIsRecruiterSelected,
  txIsRecruiterShortlisted,
  txIsReviewed,
} from '../../util/transaction';
import { closeListing } from '../InboxJobsPage/InboxJobsPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const SHORTLIST_LIMIT = 999999999999;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { proposalModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      proposalModalOpen: proposalModalOpenForListingId === params.id,
      proposal: null,
      isNotificationModalOpen: true,
    };

    this.refScrollUp = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitProposal = this.onSubmitProposal.bind(this);
    this.onOpenProposal = this.onOpenProposal.bind(this);
    this.onCloseProposal = this.onCloseProposal.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleShortlisRecruiter = this.handleShortlisRecruiter.bind(this);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, quantity: quantityRaw, deliveryMethod, ...otherOrderData } = values;
    const bookingDatesMaybe = bookingDates
      ? {
          bookingDates: {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          },
        }
      : {};

    const initialValues = {
      listing,
      orderData: {
        ...bookingDatesMaybe,
        quantity: Number.parseInt(quantityRaw, 10),
        deliveryMethod,
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { proposalModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ proposalModalOpen: true });
    }
  }

  onSubmitProposal(values) {
    const { history, params, onSendProposal, onSpendToken } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { totalPrice } = values;

    const proposal = {
      ...values,
      totalPrice: serialize(totalPrice),
      totalPriceForEmail: { amount: totalPrice.amount * 0.01, currency: totalPrice.currency },
    };

    onSpendToken()
      .then(user => {
        onSendProposal(listingId, proposal)
          .then(txId => {
            this.setState({ proposalModalOpen: false });

            // Redirect to OrderDetailsPage
            history.push(
              createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
            );
          })
          .catch(() => {
            // Ignore, error handling in duck file
          });
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onOpenProposal(proposal) {
    this.scrollToTop();
    this.setState({
      proposal: proposal,
    });
  }

  onCloseProposal() {
    this.setState({
      proposal: null,
    });
  }

  scrollToTop() {
    const isMobileLayout = this.props.viewport.width < 1024;
    if (isMobileLayout) {
      this.refScrollUp.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleShortlisRecruiter(proposalId) {
    const { currentUser, onShortlistRecruiter } = this.props;
    const companyData = {
      contactEmail: currentUser.attributes.email,
      contactName: `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`,
      ...currentUser.attributes.profile.protectedData,
    };

    onShortlistRecruiter(proposalId, companyData);
  }

  onCloseNotificationModal = () => {
    const { onUpdateListing, params } = this.props;
    onUpdateListing(params.id);
    this.setState({ isNotificationModalOpen: false });
  };

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      proposals,
      fetchProposalsInProgress,
      fetchProposalsError,
      sendProposalInProgress,
      sendProposalError,
      spendTokenInProgress,
      spendTokenError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      shortlistRecruiterInProgress,
      shortlistRecruiterError,
      declineProposalInProgress,
      declineProposalError,
      onDeclineRecruiterProposal,
      declineFromShortlistInProgress,
      declineFromShortlistError,
      onDeclineFromShortlist,
      onCloseListing,
      closingListing,
      closingListingError,
    } = this.props;

    const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const hasListingState = !!currentListing.attributes.state;
    const isClosed = hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      // geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const isOwner = currentUser && currentUser.id.uuid === currentAuthor?.id.uuid;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.onContactUser(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      img => img.url
    );
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};
    const schemaPriceNumber = intl.formatNumber(convertMoneyToNumber(price), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const categoryOptions = findOptionsForSelectFilter('category', customConfig.filters);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;
    const isRead = publicData && publicData.notified;

    const isCompany =
      currentUser && currentUser.attributes.profile.publicData
        ? !!currentUser.attributes.profile.publicData.isCompany
        : false;

    const stateDataFn = proposals => {
      if (proposals.length > 0 && proposals.every(txIsProposed)) {
        return {
          currentStep: 'interestShown',
        };
      } else if (proposals.some(txIsRecruiterSelected)) {
        return {
          currentStep: 'selectRecruiter',
        };
      } else if (proposals.some(txIsRecruiterShortlisted)) {
        return {
          currentStep: 'shortlistedRecruiters',
        };
      } else if (proposals.length > 0 && proposals.every(txIsDeclined)) {
        return {
          currentStep: 'interestShown',
        };
      } else if (proposals.some(txIsCandidatesShortlistAccepted)) {
        return {
          currentStep: 'signingOfAgreement',
        };
      } else if (
        proposals.some(txIsCandidateHired) ||
        proposals.some(tx => txIsInFirstReviewBy(tx, true))
      ) {
        return {
          currentStep: 'reviewRecruiter',
        };
      } else if (
        proposals.some(txIsReviewed) ||
        proposals.some(tx => txIsInFirstReviewBy(tx, false))
      ) {
        return {
          currentStep: 'reviewed',
        };
      } else {
        return { currentStep: 'jobPublished' };
      }
    };

    const setCompletedSteps = stateData => {
      const completedStepsIn = [
        { key: 'jobPublished', completed: true },
        { key: 'interestShown', completed: false },
        { key: 'shortlistedRecruiters', completed: false },
        { key: 'selectRecruiter', completed: false },
        { key: 'signingOfAgreement', completed: false },
        { key: 'reviewRecruiter', completed: false },
      ];
      const { currentStep } = stateData;
      const allComplete = currentStep === 'reviewed';
      const currentStepIndex = completedStepsIn.findIndex(s => s.key === currentStep);
      const completedSteps = completedStepsIn.map((step, index) => {
        return allComplete
          ? {
              key: step.key,
              completed: true,
            }
          : {
              key: step.key,
              completed: currentStepIndex > index,
            };
      });
      return {
        completedSteps,
        currentStep: allComplete ? false : currentStep,
      };
    };

    const stateData = stateDataFn(proposals);
    const completedSteps = setCompletedSteps(stateData);

    const showProgressBar = isCompany && isOwnListing;

    const canPlaceProposals =
      proposals
        .map(tx => !!txCandidateHasBeenShortlisted(tx))
        .reduce((sum, curr) => (curr ? sum + 1 : sum), 0) < SHORTLIST_LIMIT;

    const selectedProposal = this.state.proposal
      ? proposals.find(p => p.id.uuid === this.state.proposal.id.uuid)
      : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            priceCurrency: price.currency,
            price: schemaPriceNumber,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div
              className={classNames(css.contentWrapperForProductLayout, {
                [css.hasProgressBar]: showProgressBar,
              })}
              ref={this.refScrollUp}
            >
              {showProgressBar && (
                <div className={css.progressBarWrapper}>
                  <JobProgressBar
                    className={classNames(css.progressBar, 'hideOnMobile')}
                    stepData={completedSteps}
                  />
                </div>
              )}
              <div className={css.mainColumnForProductLayout}>
                <div className={css.productMobileHeading}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    authorLink={authorLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                  />
                  {showProgressBar && (
                    <JobProgressBar
                      className={classNames(css.progressBar, 'hideOnDesktop')}
                      stepData={completedSteps}
                    />
                  )}
                  {isCompany && isOwnListing && currentListing.id && !isClosed && (
                    <JobActions
                      currentListing={currentListing}
                      onCloseListing={onCloseListing}
                      closingListing={closingListing}
                      closingListingError={closingListingError}
                    />
                  )}
                </div>
                {selectedProposal ? (
                  <SectionProposal
                    proposal={selectedProposal}
                    richTitle={richTitle}
                    onCloseProposal={this.onCloseProposal}
                    canPlaceProposals={canPlaceProposals}
                    onShortlistRecruiter={this.handleShortlisRecruiter}
                    shortlistRecruiterInProgress={shortlistRecruiterInProgress}
                    shortlistRecruiterError={shortlistRecruiterError}
                    declineProposalInProgress={declineProposalInProgress}
                    declineProposalError={declineProposalError}
                    onDeclineRecruiterProposal={onDeclineRecruiterProposal}
                    declineFromShortlistInProgress={declineFromShortlistInProgress}
                    declineFromShortlistError={declineFromShortlistError}
                    onDeclineFromShortlist={onDeclineFromShortlist}
                    lightCloseButton
                    intl={intl}
                  />
                ) : (
                  <>
                    <SectionDetailsMaybe
                      title={title}
                      publicData={publicData}
                      customConfig={customConfig}
                    />
                    <SectionDescriptionMaybe description={description} listingTitle={richTitle} />
                    <SectionContent publicData={publicData} fieldName="mainTasks" />
                    <SectionContent publicData={publicData} fieldName="supportingTasks" />
                    <SectionContent publicData={publicData} fieldName="managerialResponsibility" />
                    <SectionContent publicData={publicData} fieldName="leadershipTeamRole" />
                    <SectionQualification publicData={publicData} />
                    <SectionCompensation publicData={publicData} />
                  </>
                )}
              </div>
              <div className={css.orderColumnForProductLayout}>
                {isOwner && (
                  <div ref={this.proposalEl}>
                    <div className={css.proposalHelp}>
                      <p className={css.proposalInfo}>
                        <FormattedMessage id="ListingPage.proposalsCheckout" />
                      </p>
                    </div>
                    <SectionProposals
                      proposals={proposals}
                      fetchProposalsInProgress={fetchProposalsInProgress}
                      fetchProposalsError={fetchProposalsError}
                      onOpenProposal={this.onOpenProposal}
                      openProposalId={this.state.proposal ? this.state.proposal.id.uuid : null}
                    />
                  </div>
                )}
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleOrderSubmit}
                  title={bookingTitle}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={this.onContactUser}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  showJobActions={isCompany && isOwnListing}
                  currentListing={currentListing}
                  canPlaceProposals={canPlaceProposals}
                  fetchProposalsInProgress={fetchProposalsInProgress}
                  onCloseListing={onCloseListing}
                  closingListing={closingListing}
                  closingListingError={closingListingError}
                />
              </div>
            </div>

            <Modal
              id="ListingPage.proposal"
              contentClassName={css.proposalModalContent}
              isOpen={isAuthenticated && this.state.proposalModalOpen}
              onClose={() => this.setState({ proposalModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <ProposalForm
                className={css.proposalForm}
                submitButtonWrapperClassName={css.proposalSubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                currentUser={currentUser}
                sendProposalError={sendProposalError}
                spendTokenError={spendTokenError}
                onSubmit={this.onSubmitProposal}
                inProgress={sendProposalInProgress || spendTokenInProgress}
              />
            </Modal>

            <NotificationModal
              id="NotificationModal"
              contentClassName={css.modalContent}
              isOpen={this.state.isNotificationModalOpen && isOwnListing && !isRead}
              onClose={() => this.onCloseNotificationModal()}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
              closeButtonMessage={closeButtonMessage}
            >
              <p className={css.modalMessage}>
                <FormattedMessage id="ReviewModal.description" />
              </p>
            </NotificationModal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  proposalModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  proposals: [],
  fetchProposalsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendProposalError: null,
  spendTokenError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  shortlistRecruiterInProgress: bool.isRequired,
  shortlistRecruiterError: propTypes.error,
  onShortlistRecruiter: func.isRequired,
  declineProposalInProgress: bool.isRequired,
  declineProposalError: propTypes.error,
  onDeclineRecruiterProposal: func.isRequired,
  declineFromShortlistInProgress: bool.isRequired,
  declineFromShortlistError: propTypes.error,
  onDeclineFromShortlist: func.isRequired,
  proposalModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  proposals: arrayOf(propTypes.transaction).isRequired,
  fetchProposalsInProgress: bool.isRequired,
  fetchProposalsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendProposalInProgress: bool.isRequired,
  sendProposalError: propTypes.error,
  spendTokenInProgress: bool.isRequired,
  spendTokenError: propTypes.error,
  onSendProposal: func.isRequired,
  onSpendToken: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  onCloseListing: func.isRequired,
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    proposalRefs,
    fetchProposalsInProgress,
    fetchProposalsError,
    timeSlots,
    fetchTimeSlotsError,
    sendProposalInProgress,
    sendProposalError,
    spendTokenInProgress,
    spendTokenError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    proposalModalOpenForListingId,
  } = state.ListingPage;
  const {
    shortlistRecruiterInProgress,
    shortlistRecruiterError,
    declineProposalInProgress,
    declineProposalError,
    declineFromShortlistInProgress,
    declineFromShortlistError,
  } = state.TransactionPage;
  const { closingListing, closingListingError } = state.InboxJobsPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    proposalModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    proposals: getMarketplaceEntities(state, proposalRefs),
    fetchProposalsInProgress,
    fetchProposalsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendProposalInProgress,
    sendProposalError,
    shortlistRecruiterInProgress,
    shortlistRecruiterError,
    declineProposalInProgress,
    declineProposalError,
    declineFromShortlistInProgress,
    declineFromShortlistError,
    closingListing,
    closingListingError,
    spendTokenInProgress,
    spendTokenError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendProposal: (listingId, message) => dispatch(sendProposal(listingId, message)),
  onSpendToken: tokens => dispatch(spendToken(tokens)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onShortlistRecruiter: (transacrionId, companyData) =>
    dispatch(shortlistRecruiter(transacrionId, companyData)),
  onDeclineRecruiterProposal: transacrionId => dispatch(declineProposal(transacrionId)),
  onDeclineFromShortlist: transacrionId => dispatch(declineFromShortlist(transacrionId, false)),
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onUpdateListing: params => dispatch(updateListing(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
