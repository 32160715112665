import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionCompensation = props => {
  const { publicData } = props;

  if (!publicData) {
    return null;
  }

  const compensationModel = publicData.compensationModel;
  const monthlySalary = publicData.monthlySalary;
  const otherBenefits = publicData.otherBenefits;

  return (
    <div className={css.sectionCompensation}>
      <h2 className={css.compensationTitle}>
        <FormattedMessage id="ListingPage.compensation" />
      </h2>
      <ul className={css.compensations}>
        <li className={css.compensationRow}>
          <span className={css.compensationLabel}>
            <FormattedMessage id="ListingPage.compensationModel" />
          </span>
          <span>{compensationModel}</span>
        </li>
        <li className={css.compensationRow}>
          <span className={css.compensationLabel}>
            <FormattedMessage id="ListingPage.monthlySalary" />
          </span>
          <span>{monthlySalary}</span>
        </li>
        <li className={css.compensationRow}>
          <span className={css.compensationLabel}>
            <FormattedMessage id="ListingPage.otherBenefits" />
          </span>
          <span>{otherBenefits}</span>
        </li>
      </ul>
    </div>
  );
};

export default SectionCompensation;
