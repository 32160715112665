import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Modal } from '../../../components';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import css from './NotificationModal.module.css';

const NotificationModal = props => {
  const { className, id, intl, isOpen, onClose, onManageDisableScrolling, rootClassName } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'NotificationModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <div className={css.modalHeading}>
        <p className={css.modalTitle}>
          <FormattedMessage id="NotificationModal.title" />
        </p>
      </div>
      <p className={css.modalMessage}>
        <FormattedMessage id="NotificationModal.description" />
      </p>
    </Modal>
  );
};

const { string } = PropTypes;

NotificationModal.defaultProps = {
  className: null,
  rootClassName: null,
};

NotificationModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(NotificationModal);
