import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { deserialize } from '../../util/api';
import { formatDateIntoPartials } from '../../util/dates';
import { formatMoney } from '../../util/currency';
import { Avatar, Button, IconClose, NamedLink, UserDisplayName } from '../../components';

import css from './ListingPage.module.css';
import {
  txCandidateHasBeenShortlisted,
  txIsDeclined,
  txIsProposed,
  txIsRecruiterShortlisted,
} from '../../util/transaction';

const SectionProposal = props => {
  const {
    proposal,
    richTitle,
    onCloseProposal,
    closeButtonMessage,
    lightCloseButton,
    intl,
    canPlaceProposals,
    shortlistRecruiterInProgress,
    shortlistRecruiterError,
    onShortlistRecruiter,
    declineProposalInProgress,
    declineProposalError,
    onDeclineRecruiterProposal,
    declineFromShortlistInProgress,
    declineFromShortlistError,
    onDeclineFromShortlist,
  } = props;
  const user = proposal && proposal.customer;
  const userHasAttributes = user && user.attributes;
  const userHasProfile = userHasAttributes && user.attributes.profile;
  const userName = userHasProfile && user.attributes.profile.displayName;
  const userHasPublicData = userHasProfile && user.attributes.profile.publicData;
  const userCompanyName = userHasPublicData && user.attributes.profile.publicData.companyName;
  const proposalHasAttributes = proposal && proposal.attributes;
  const proposalHasProtectedData = proposalHasAttributes && proposal.attributes.protectedData;
  const proposalData = proposalHasProtectedData && proposal.attributes.protectedData.proposal;
  const proposalDate = formatDateIntoPartials(proposal.attributes.createdAt, intl);
  const userCompanyLocation =
    userHasPublicData && user.attributes.profile.publicData.companyAddress;

  const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
  const closeButtonClasses = classNames(css.close, {
    [css.closeLight]: lightCloseButton,
  });
  const closeBtn = (
    <Button onClick={onCloseProposal} rootClassName={closeButtonClasses} title={closeModalMessage}>
      <span className={css.closeText}>
        {closeButtonMessage || <FormattedMessage id="Modal.close" />}
      </span>
      <IconClose rootClassName={css.closeIcon} />
    </Button>
  );

  const handleShortlistRecruiter = () => {
    onShortlistRecruiter(proposal.id);
  };

  const handleDeclineRecruiter = () => {
    if (txIsProposed(proposal)) {
      onDeclineRecruiterProposal(proposal.id);
    } else if (txIsRecruiterShortlisted(proposal)) {
      onDeclineFromShortlist(proposal.id);
    }
  };

  const isDeclineInProgress = declineProposalInProgress || declineFromShortlistInProgress;

  const canShortlistRecruiter =
    !txIsDeclined(proposal) && !txCandidateHasBeenShortlisted(proposal) && canPlaceProposals;
  const canDeclineProposal = txIsProposed(proposal);

  return (
    <>
      {closeBtn}
      <div className={css.proposal}>
        <div className={css.proposalHeader}>
          <Avatar className={css.avatar} user={user} />
          <div className={css.proposalCompanyInfo}>
            <h2>
              <NamedLink name="ProfilePage" params={{ id: user.id.uuid }}>
                <UserDisplayName user={user} intl={intl} className={css.forCompany} />
              </NamedLink>
              <FormattedMessage
                id="ListingPage.proposalsCompany"
                values={{ company: userCompanyName }}
              />
              <NamedLink
                name="ProfilePage"
                params={{ id: user.id.uuid }}
                className={css.proposalCompanyLink}
              >
                <FormattedMessage id="ListingPage.aboutRecruiter" />
              </NamedLink>
            </h2>
            <p className={css.proposalCompanyMeta}>
              <span>
                {userCompanyLocation &&
                  `${userCompanyLocation.city}, ${userCompanyLocation.country} • `}
              </span>
              <FormattedMessage
                id="ListingPage.proposalsTime"
                values={{ date: proposalDate.dateAndTime }}
              />
            </p>
          </div>
        </div>

        {canShortlistRecruiter && (
          <div className={css.proposalOffer}>
            <p className={css.shortlistCandidateNotice}>
              By selecting to <b>shortlist</b> below, you add this recruitment service provider to
              the next stage and <b>provide them with your company details</b>. Afterwards, they may
              contact you to discuss the job details. <b>Declining</b> a proposal will notify the
              recruiter, and you cannot shortlist them anymore.
            </p>
          </div>
        )}

        {proposalData && (
          <div className={css.proposalOffer}>
            <div>
              <h4 className={css.proposalOfferLabel}>
                <FormattedMessage id="ListingPage.proposalPrice" />
              </h4>
              <p className={css.proposalInfo}>
                {formatMoney(intl, deserialize(proposalData.totalPrice))}
              </p>
            </div>
            <div>
              <h4 className={css.proposalOfferLabel}>
                <FormattedMessage id="ListingPage.proposalEstimatedTime" />
              </h4>
              <p className={css.proposalInfo}>{proposalData.recruitmentEstimatedTime}</p>
            </div>
            <div>
              {canShortlistRecruiter && (
                <Button
                  className={css.shortlistCompanyLink}
                  onClick={handleShortlistRecruiter}
                  inProgress={shortlistRecruiterInProgress}
                >
                  <FormattedMessage id="ListingPage.shortlist" />
                </Button>
              )}

              {txCandidateHasBeenShortlisted(proposal) && (
                <NamedLink
                  className={css.gotoCompanyLink}
                  name="SaleDetailsPage"
                  params={{ id: proposal.id.uuid }}
                >
                  <FormattedMessage id="ListingPage.goToProposal" />
                </NamedLink>
              )}

              {canDeclineProposal && (
                <Button
                  className={css.shortlistCompanyLink}
                  onClick={handleDeclineRecruiter}
                  inProgress={isDeclineInProgress}
                >
                  <FormattedMessage id="ListingPage.decline" />
                </Button>
              )}
            </div>
          </div>
        )}

        {shortlistRecruiterError ? (
          <p className={css.errorText}>
            <FormattedMessage id="ListingPage.shortlistRecruiterError" />
          </p>
        ) : null}

        {declineProposalError ? (
          <p className={css.errorText}>
            <FormattedMessage id="ListingPage.declineProposalError" />
          </p>
        ) : null}

        {declineFromShortlistError ? (
          <p className={css.errorText}>
            <FormattedMessage id="ListingPage.declineFromShortlistError" />
          </p>
        ) : null}

        <hr />

        {proposalData && (
          <>
            <h3 className={css.proposalSubheading}>
              <FormattedMessage id="ListingPage.proposalWhyChoose" />
            </h3>
            <p className={css.proposalInfo}>{proposalData.whyShouldYouBeChoosen}</p>
            <h3 className={css.proposalSubheading}>
              <FormattedMessage id="ListingPage.proposalIndustryExperience" />
            </h3>
            <p className={css.proposalInfo}>{proposalData.industryExperience}</p>
            <h3 className={css.proposalSubheading}>
              <FormattedMessage id="ListingPage.proposalPublicReferences" />
            </h3>
            <p className={css.proposalInfo}>{proposalData.publicReferences}</p>
            <h3 className={css.proposalSubheading}>
              <FormattedMessage id="ListingPage.proposalOtherAreas" />
            </h3>
            <p className={css.proposalInfo}>{proposalData.otherAreasOfConsideration}</p>
            <h3 className={css.proposalSubheading}>
              <FormattedMessage id="ListingPage.proposalResources" />
            </h3>
            <p className={css.proposalInfo}>{proposalData.additionalResourcesUsed}</p>
            <h3 className={css.proposalSubheading}>
              <FormattedMessage id="ListingPage.proposalRecruitmentEstimatedTime" />
            </h3>
            <p className={css.proposalInfo}>{proposalData.recruitmentEstimatedTime}</p>
            <h3 className={css.proposalSubheading}>
              <FormattedMessage id="ListingPage.serviceGuarantee" />
            </h3>
            <p className={css.proposalInfo}>{proposalData.serviceGuarantee}</p>
          </>
        )}
        <p className={css.proposalInfo}>
          <FormattedMessage
            id="ListingPage.proposalsJob"
            values={{ user: userName, job: richTitle }}
          />
        </p>
      </div>
    </>
  );
};

export default SectionProposal;
