import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionContent = props => {
  const { className, fieldName, publicData } = props;

  if (!publicData) {
    return null;
  }

  const content = publicData[fieldName];

  return content ? (
    <div className={classNames(css.sectionContent, className)}>
      <h2 className={css.contentTitle}>
        <FormattedMessage id={`ListingPage.${fieldName}`} />
      </h2>
      <p className={css.content}>
        {richText(content, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionContent;
